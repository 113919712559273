import React from "react"
import { graphql, Link } from "gatsby"
import { Container, Row, Col, Image } from 'react-bootstrap'
import Layout from "../components/layout"
import Header from "../../src/img/header.jpg"
// import { Link } from "../components/link"
import '../styles/global.scss'

export default function Template({
    data, // this prop will be injected by the GraphQL query below.
}) {
    const { markdownRemark } = data // data.markdownRemark holds your post data
    const { frontmatter, html } = markdownRemark
    return (
        <Layout>
            <Container className="position-relative d-md-flex justify-content-center align-items-center px-0 mb-5" fluid>
                <Image src={Header} fluid />
                <Container className="position-md-absolute text-center">
                    <Row>
                        <Col xs="12" className="d-none d-md-block">
                            <h2 className="text-white mb-3">
                                Dr. Ernstfried Hanisch
                            </h2>
                            <h1 className="text-white mb-0">
                                FAQ
                            </h1>
                        </Col>
                        <Col xs="12" className="d-md-none mt-5">
                            <h2 className="mb-3">
                                Dr. Ernstfried Hanisch
                            </h2>
                            <h1 className="mb-0">
                                FAQ
                            </h1>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container>
                <Row className="mb-5">
                    <Col xs="12">
                        <h3 className="mb-2">
                            {frontmatter.title}
                        </h3>
                        <p className="mb-4">aus den <Link to="/faq"><span className="back-btn">FAQ</span></Link>  •  Lesezeit: {frontmatter.time}  •  Autor: Hr. Dr. Hanisch</p>
                    </Col>
                    <Col>
                        <div
                            dangerouslySetInnerHTML={{ __html: html }}
                        />
                        <p className="mb-0 back-btn">
                            <Link to="/faq" className="back-btn">
                                <span className="back-btn">
                                {"<"} zurück zu den FAQ
                                </span>
                            </Link>
                        </p>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
        time
      }
    }
  }
`